exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-asset-management-company-about-us-js": () => import("./../../../src/pages/asset-management/company/about-us.js" /* webpackChunkName: "component---src-pages-asset-management-company-about-us-js" */),
  "component---src-pages-asset-management-company-management-js": () => import("./../../../src/pages/asset-management/company/management.js" /* webpackChunkName: "component---src-pages-asset-management-company-management-js" */),
  "component---src-pages-asset-management-contact-js": () => import("./../../../src/pages/asset-management/contact.js" /* webpackChunkName: "component---src-pages-asset-management-contact-js" */),
  "component---src-pages-asset-management-index-js": () => import("./../../../src/pages/asset-management/index.js" /* webpackChunkName: "component---src-pages-asset-management-index-js" */),
  "component---src-pages-asset-management-services-asset-life-cycle-management-js": () => import("./../../../src/pages/asset-management/services/asset-life-cycle-management.js" /* webpackChunkName: "component---src-pages-asset-management-services-asset-life-cycle-management-js" */),
  "component---src-pages-asset-management-services-commercial-management-js": () => import("./../../../src/pages/asset-management/services/commercial-management.js" /* webpackChunkName: "component---src-pages-asset-management-services-commercial-management-js" */),
  "component---src-pages-asset-management-services-insurance-and-claims-js": () => import("./../../../src/pages/asset-management/services/insurance-and-claims.js" /* webpackChunkName: "component---src-pages-asset-management-services-insurance-and-claims-js" */),
  "component---src-pages-asset-management-services-new-building-js": () => import("./../../../src/pages/asset-management/services/new-building.js" /* webpackChunkName: "component---src-pages-asset-management-services-new-building-js" */),
  "component---src-pages-asset-management-services-technical-and-crew-management-js": () => import("./../../../src/pages/asset-management/services/technical-and-crew-management.js" /* webpackChunkName: "component---src-pages-asset-management-services-technical-and-crew-management-js" */),
  "component---src-pages-bulk-logistics-company-about-us-js": () => import("./../../../src/pages/bulk-logistics/company/about-us.js" /* webpackChunkName: "component---src-pages-bulk-logistics-company-about-us-js" */),
  "component---src-pages-bulk-logistics-company-js": () => import("./../../../src/pages/bulk-logistics/company.js" /* webpackChunkName: "component---src-pages-bulk-logistics-company-js" */),
  "component---src-pages-bulk-logistics-company-management-js": () => import("./../../../src/pages/bulk-logistics/company/management.js" /* webpackChunkName: "component---src-pages-bulk-logistics-company-management-js" */),
  "component---src-pages-bulk-logistics-contact-copenhagen-js": () => import("./../../../src/pages/bulk-logistics/contact/copenhagen.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-copenhagen-js" */),
  "component---src-pages-bulk-logistics-contact-delhi-js": () => import("./../../../src/pages/bulk-logistics/contact/delhi.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-delhi-js" */),
  "component---src-pages-bulk-logistics-contact-dubai-js": () => import("./../../../src/pages/bulk-logistics/contact/dubai.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-dubai-js" */),
  "component---src-pages-bulk-logistics-contact-dublin-js": () => import("./../../../src/pages/bulk-logistics/contact/dublin.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-dublin-js" */),
  "component---src-pages-bulk-logistics-contact-general-inquiry-js": () => import("./../../../src/pages/bulk-logistics/contact/general-inquiry.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-general-inquiry-js" */),
  "component---src-pages-bulk-logistics-contact-mumbai-js": () => import("./../../../src/pages/bulk-logistics/contact/mumbai.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-mumbai-js" */),
  "component---src-pages-bulk-logistics-contact-singapore-js": () => import("./../../../src/pages/bulk-logistics/contact/singapore.js" /* webpackChunkName: "component---src-pages-bulk-logistics-contact-singapore-js" */),
  "component---src-pages-bulk-logistics-index-js": () => import("./../../../src/pages/bulk-logistics/index.js" /* webpackChunkName: "component---src-pages-bulk-logistics-index-js" */),
  "component---src-pages-bulk-logistics-legal-cookie-policy-js": () => import("./../../../src/pages/bulk-logistics/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-bulk-logistics-legal-cookie-policy-js" */),
  "component---src-pages-bulk-logistics-legal-privacy-policy-js": () => import("./../../../src/pages/bulk-logistics/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-bulk-logistics-legal-privacy-policy-js" */),
  "component---src-pages-bulk-logistics-products-js": () => import("./../../../src/pages/bulk-logistics/products.js" /* webpackChunkName: "component---src-pages-bulk-logistics-products-js" */),
  "component---src-pages-bulk-logistics-services-asset-management-js": () => import("./../../../src/pages/bulk-logistics/services/asset-management.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-asset-management-js" */),
  "component---src-pages-bulk-logistics-services-commercial-management-js": () => import("./../../../src/pages/bulk-logistics/services/commercial-management.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-commercial-management-js" */),
  "component---src-pages-bulk-logistics-services-drybulk-and-tanker-ocean-freight-js": () => import("./../../../src/pages/bulk-logistics/services/drybulk-and-tanker-ocean-freight.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-drybulk-and-tanker-ocean-freight-js" */),
  "component---src-pages-bulk-logistics-services-risk-management-js": () => import("./../../../src/pages/bulk-logistics/services/risk-management.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-risk-management-js" */),
  "component---src-pages-bulk-logistics-services-storage-js": () => import("./../../../src/pages/bulk-logistics/services/storage.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-storage-js" */),
  "component---src-pages-bulk-logistics-services-three-wheels-united-js": () => import("./../../../src/pages/bulk-logistics/services/three-wheels-united.js" /* webpackChunkName: "component---src-pages-bulk-logistics-services-three-wheels-united-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-contact-general-inquiry-js": () => import("./../../../src/pages/contact/general-inquiry.js" /* webpackChunkName: "component---src-pages-contact-general-inquiry-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-energy-logistics-company-about-us-js": () => import("./../../../src/pages/energy-logistics/company/about-us.js" /* webpackChunkName: "component---src-pages-energy-logistics-company-about-us-js" */),
  "component---src-pages-energy-logistics-company-team-js": () => import("./../../../src/pages/energy-logistics/company/team.js" /* webpackChunkName: "component---src-pages-energy-logistics-company-team-js" */),
  "component---src-pages-energy-logistics-contact-js": () => import("./../../../src/pages/energy-logistics/contact.js" /* webpackChunkName: "component---src-pages-energy-logistics-contact-js" */),
  "component---src-pages-energy-logistics-index-js": () => import("./../../../src/pages/energy-logistics/index.js" /* webpackChunkName: "component---src-pages-energy-logistics-index-js" */),
  "component---src-pages-energy-logistics-products-bio-fuels-js": () => import("./../../../src/pages/energy-logistics/products/bio-fuels.js" /* webpackChunkName: "component---src-pages-energy-logistics-products-bio-fuels-js" */),
  "component---src-pages-energy-logistics-products-carbon-credits-js": () => import("./../../../src/pages/energy-logistics/products/carbon-credits.js" /* webpackChunkName: "component---src-pages-energy-logistics-products-carbon-credits-js" */),
  "component---src-pages-energy-logistics-products-iso-8217-js": () => import("./../../../src/pages/energy-logistics/products/iso-8217.js" /* webpackChunkName: "component---src-pages-energy-logistics-products-iso-8217-js" */),
  "component---src-pages-energy-logistics-services-credit-and-risk-management-js": () => import("./../../../src/pages/energy-logistics/services/credit-and-risk-management.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-credit-and-risk-management-js" */),
  "component---src-pages-energy-logistics-services-digital-solutions-js": () => import("./../../../src/pages/energy-logistics/services/digital-solutions.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-digital-solutions-js" */),
  "component---src-pages-energy-logistics-services-lubricants-blending-and-distribution-js": () => import("./../../../src/pages/energy-logistics/services/lubricants-blending-and-distribution.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-lubricants-blending-and-distribution-js" */),
  "component---src-pages-energy-logistics-services-marine-fuels-sales-js": () => import("./../../../src/pages/energy-logistics/services/marine-fuels-sales.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-marine-fuels-sales-js" */),
  "component---src-pages-energy-logistics-services-physical-supply-js": () => import("./../../../src/pages/energy-logistics/services/physical-supply.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-physical-supply-js" */),
  "component---src-pages-energy-logistics-services-retail-fuel-distribution-js": () => import("./../../../src/pages/energy-logistics/services/retail-fuel-distribution.js" /* webpackChunkName: "component---src-pages-energy-logistics-services-retail-fuel-distribution-js" */),
  "component---src-pages-governance-js": () => import("./../../../src/pages/governance.js" /* webpackChunkName: "component---src-pages-governance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-logistic-park-index-js": () => import("./../../../src/pages/logistic-park/index.js" /* webpackChunkName: "component---src-pages-logistic-park-index-js" */),
  "component---src-pages-vision-and-values-js": () => import("./../../../src/pages/vision-and-values.js" /* webpackChunkName: "component---src-pages-vision-and-values-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-delta-corp-news-item-js": () => import("./../../../src/templates/delta-corp/news/item.js" /* webpackChunkName: "component---src-templates-delta-corp-news-item-js" */),
  "component---src-templates-delta-corp-news-list-js": () => import("./../../../src/templates/delta-corp/news/list.js" /* webpackChunkName: "component---src-templates-delta-corp-news-list-js" */)
}

